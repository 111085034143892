// eslint-disable-next-line no-unused-vars
import React, { CSSProperties } from 'react'
import styles from './Cell.module.css'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { CellState } from '../../../reducers/AHC000/state'
import { CellProps, cellClasses, cellStyle } from './Cell'
import classNames from 'classnames'

const MainCell: React.FC<CellProps> = (props: CellProps) => {
  const y = props.y
  const x = props.x
  const cellState: CellState = useSelector((state: any) => {
    return state.AHC000.cells[y][x]
  }, shallowEqual)
  // console.log(`re-rendered y:${y} x:${x}`)
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch({
      type: 'GRIDSTATE_UPDATE',
      payload: { y: y, x: x },
    })
  }

  const valueBrightnessMax = -5000
  const valueBrightnessMin = 0
  const hslBrightnessMax = 50
  const hslBrightnessMin = 100
  const hslBrightnessValue =
    hslBrightnessMin +
    ((hslBrightnessMax - hslBrightnessMin) *
      (Math.max(
        valueBrightnessMax,
        Math.min(valueBrightnessMin, cellState.penalty)
      ) -
        valueBrightnessMin)) /
      (valueBrightnessMax - valueBrightnessMin)

  const hslBrightness = `${hslBrightnessValue}%`

  const classes = cellClasses(props, 'main-cell')
  const style = {
    color: cellState.isHeld ? 'blue' : 'black',
    fontWeight: cellState.isHeld ? 'bold' : 'normal',
    backgroundColor: `hsl(0, 100%, ${hslBrightness})`,
    ...cellStyle(props),
  } as CSSProperties
  const content = cellState.holdScore

  return (
    <div className={classNames(classes)} style={style} onClick={onClick}>
      <div className={styles['inner-cell']}>{content}</div>
    </div>
  )
}

export default MainCell
