// eslint-disable-next-line no-unused-vars
import React, { CSSProperties } from 'react'
import styles from './Cell.module.css'
import { shallowEqual, useSelector } from 'react-redux'
import { CellProps, cellClasses, cellStyle } from './Cell'
import classNames from 'classnames'

const HeaderCellFactor: React.FC<CellProps> = (props: CellProps) => {
  const headerValue: string = useSelector((state: any) => {
    return state.AHC000.contestPenaltyFactor[props.x]
  }, shallowEqual)

  const classes = cellClasses(props, 'header-factor-cell')
  const style = cellStyle(props)
  const content = headerValue

  return (
    <div className={classNames(classes)} style={style}>
      <div className={styles['inner-cell']}>{content}</div>
    </div>
  )
}

export default HeaderCellFactor
