import React from 'react'

export const flexVerticalStyle = {
  display: 'flex',
  flexDirection: 'column',
} as React.CSSProperties

export const flexHorizontalStyle = {
  display: 'flex',
  flexDirection: 'row',
} as React.CSSProperties

export const flexContainerStyle = {
  flex: 'none',
  position: 'relative',
}

export const generateCreateCellProps = (
  H: number,
  W: number,
  cellHeight: number,
  cellWidth: number
) => (x: number, y: number) => {
  const props: any = {
    y: y,
    x: x,
    top: cellHeight * y,
    left: cellWidth * x,
    height: cellHeight,
    width: cellWidth,
    key: y * W + x,
    H: H,
    W: W,
  }
  return props
}

export const createComponentMatrix = (
  H: number,
  W: number,
  gen: (x: number, y: number) => any
) => {
  const mat = []
  for (let y = 0; y < H; y++) {
    for (let x = 0; x < W; x++) {
      mat.push(gen(x, y))
    }
  }
  return mat
}
