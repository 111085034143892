// eslint-disable-next-line no-unused-vars
import React, { CSSProperties } from 'react'
import styles from './Cell.module.css'

export type CellProps = {
  y: number
  x: number
  top: number
  left: number
  width: number
  height: number
  H: number
  W: number
}

export function cellClasses(props: CellProps, styleName: string): string[] {
  const classes: any = {}
  classes[styles['cell']] = true
  classes[styles['is-right-cell']] = props.x === props.W - 1
  classes[styles['is-bottom-cell']] = props.y === props.H - 1
  classes[styles[styleName]] = true
  return classes
}

export function cellStyle(props: CellProps): CSSProperties {
  return {
    top: `${props.top}px`,
    left: `${props.left}px`,
    width: `${props.width}px`,
    height: `${props.height}px`,
  }
}
