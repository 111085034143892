import Layout from '../Layout'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Controller from './Controller'
import Grids from './Grids'
import ScoreView from './ScoreView'
import InputOutput from './InputOutput'

const Page: React.FC = () => {
  return (
    <div className="App">
      <Layout>
        <Row>
          <Col md="3">
            <Container className="py-4 mx-4">
              <Controller />
              <hr />
              <InputOutput />
              <hr />
              <ScoreView />
            </Container>
          </Col>
          <Col md="9">
            <Container className="py-4 mx-4" style={{ maxWidth: 'none' }}>
              <Grids />
            </Container>
          </Col>
        </Row>
      </Layout>
    </div>
  )
}

export default Page
