import React, { CSSProperties, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { initH, initW } from '../../reducers/AHC000/state'
import MainCell from './Cell/MainCell'
import IndexCell from './Cell/IndexCell'
import HeaderCell from './Cell/HeaderCell'
import HeaderCellFactor from './Cell/HeaderCellFactor'
import {
  createComponentMatrix,
  flexContainerStyle,
  flexHorizontalStyle,
  flexVerticalStyle,
  generateCreateCellProps,
} from './GridsUtil'

export const GridH = initH
export const GridW = initW

// const gridWidth = 1200
// const gridHeight = 1000
const marginRight = 10
const marginBottom = 10
const indexWidth = 70
const indexHeight = 900
// const headerWidth
const headerHeight = 45
const headerCellHeight = 20
const headerMidMargin = 5
const cellWidth = 40
const cellHeight = 20

const Grid: React.FC = () => {
  const state = useSelector((state: any) => {
    return state.AHC000
  })

  const headerRowStyle = {
    ...flexHorizontalStyle,
    maxWidth: 'fit-content',
  } as React.CSSProperties
  const mainRowStyle = {
    ...flexHorizontalStyle,
    maxWidth: 'fit-content',
    overflowY: 'scroll',
  } as React.CSSProperties

  return (
    <div style={flexVerticalStyle}>
      <div style={headerRowStyle}>
        <FillerView />
        <HeaderView />
      </div>
      <div style={mainRowStyle}>
        <IndexView />
        <CellsView />
      </div>
    </div>
  )
}

const FillerView = () => {
  const style = {
    marginRight: `${marginRight}px`,
    marginBottom: `${marginBottom}px`,
    width: `${indexWidth}px`,
    height: `${headerHeight}px`,
    // backgroundColor: 'green',
  }
  return <div style={style} />
}

const HeaderView = React.memo(function createHeaderView() {
  const H = 1
  const W = GridW
  const style = {
    ...flexVerticalStyle,
    marginRight: `${marginRight}px`,
    marginBottom: `${marginBottom}px`,
    width: `${cellWidth * W}px`,
    height: `${headerHeight}px`,
  } as CSSProperties

  const rowStyle = {
    ...flexContainerStyle,
    marginBottom: `${headerMidMargin}px`,
    height: headerCellHeight,
  } as CSSProperties

  const createProps = generateCreateCellProps(H, W, headerCellHeight, cellWidth)
  const matrix = createComponentMatrix(H, W, (x, y) => (
    <HeaderCell {...createProps(x, y)} />
  ))
  const matrix2 = createComponentMatrix(H, W, (x, y) => (
    <HeaderCellFactor {...createProps(x, y)} />
  ))

  return (
    <div style={{ ...style }}>
      <div style={rowStyle}>{matrix}</div>
      <div style={rowStyle}>{matrix2}</div>
    </div>
  )
})

const IndexView = React.memo(function createIndexView() {
  const H = GridH
  const W = 1
  const style = {
    ...flexContainerStyle,
    marginRight: `${marginRight}px`,
    marginBottom: `${marginBottom}px`,
    width: `${indexWidth}px`,
    height: `${indexHeight}px`, // `${cellHeight * H}px`,
  } as CSSProperties

  const createProps = generateCreateCellProps(H, W, cellHeight, indexWidth)
  const matrix = createComponentMatrix(H, W, (x, y) => (
    <IndexCell {...createProps(x, y)} />
  ))

  return (
    <div style={style}>
      <div>{matrix}</div>
    </div>
  )
})

const CellsView = React.memo(function createCellsMain() {
  const H = GridH
  const W = GridW
  console.log('cells-view rendered')

  const style = {
    ...flexContainerStyle,
    marginRight: `${marginRight}px`,
    marginBottom: `${marginBottom}px`,
    height: `${indexHeight}px`, // `${cellHeight * H}px`,
    width: `${cellWidth * W}px`,
  } as CSSProperties

  const createProps = generateCreateCellProps(H, W, cellHeight, cellWidth)
  const matrix = createComponentMatrix(H, W, (x, y) => (
    <MainCell {...createProps(x, y)} />
  ))

  return (
    <div style={style}>
      <div>{matrix} </div>
    </div>
  )
})

export default Grid
