// tslint:disable-next-line:no-var-requires
// eslint-disable-next-line @typescript-eslint/no-var-requires
const xorshift = require('xorshift')

export class XorShift {
  rand: any

  constructor(seed: number) {
    this.rand = new xorshift.constructor([seed, 1, 2, 3])
  }

  random(): number {
    return this.rand.random()
  }

  randomInt(x: number): number {
    return Math.min(Math.floor(this.rand.random() * x), x - 1)
  }
}
