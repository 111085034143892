/* eslint-disable no-unused-vars */
import React from 'react'
import { Container, Row, Button, Input, Label, Col } from 'reactstrap'
import { useSelector } from 'react-redux'

const Controller: React.FC = () => {
  const state = useSelector((state: any) => {
    return state
  })

  return (
    <>
      <Row className="my-2" style={{ textAlign: 'left' }}>
        <h5>スコア</h5>
      </Row>
      <Row className="my-0" style={{ textAlign: 'left' }}>
        RawScore: {state.AHC000.rawScore}
      </Row>
      <Row className="my-0" style={{ textAlign: 'left' }}>
        Score: {state.AHC000.score()}
      </Row>
    </>
  )
}

export default Controller
