/* eslint-disable no-unused-vars */
import React from 'react'
import { Container, Row, Button, Input, Label, Col } from 'reactstrap'
import { fetchResult } from './fetch'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

const Controller: React.FC = () => {
  const state = useSelector((state: any) => {
    return state.AHC000
  }, shallowEqual)
  const dispatch = useDispatch()

  const optimize = () => {
    fetchResult(state, dispatch)
  }
  const randomize = () => {
    const action = {
      type: 'GRIDSTATE_RANDOMIZE',
    }
    dispatch(action)
  }

  return (
    <>
      <Row className="my-2" style={{ textAlign: 'left' }}>
        <h5>概要</h5>
      </Row>
      <Row className="my-0" style={{ textAlign: 'left' }}>
        <p>
          AtCoder &nbsp;
          <a href="https://atcoder.jp/contests/intro-heuristics/tasks/intro_heuristics_a">
            Introduction to Heuristics Contest
          </a>
          のビジュアライザです。
        </p>
      </Row>
      <Row className="my-2" style={{ textAlign: 'left' }}>
        <h5>操作方法</h5>
      </Row>
      <Row className="my-0" style={{ textAlign: 'left' }}>
        「最適化する」「ランダムな解にする」は以下のボタンから実行して下さい（「最適化する」は、少し時間がかかることがあります）
      </Row>
      <Row className="my-2" style={{ textAlign: 'left' }}>
        <Button color="primary" className="mx-1" onClick={optimize}>
          Optimize!
        </Button>
        <Button color="primary" className="mx-1" onClick={randomize}>
          Randomize!
        </Button>
      </Row>
      <Row className="my-3" style={{ textAlign: 'left' }}>
        開催するコンテストのセルをクリックすることで、手動で解を変更することも可能です
      </Row>
    </>
  )
}

export default Controller
