import { State } from '../../reducers/AHC000/state'
import { Dispatch } from 'redux'

import { GoogleAuth } from 'google-auth-library'

const auth = new GoogleAuth()
const url = `${process.env.REACT_APP_BACK_END_URL}`

// テスト用
export const fetchHello = () => {
  console.log(`url: ${url}`)
  const URL = url
  fetch(URL, { mode: 'cors' })
    .then((response) => response.text())
    .then((text) => console.log(text))
    .catch((error) => console.log(error))
}

// サーバに計算を投げて結果を取得する
export const fetchResult = (state: State, dispatch: Dispatch<any>): void => {
  const URL = `${url}/run`
  const input_b64 = Buffer.from(state.inputText).toString('base64')
  const jsonData = {
    run_id: makeRunId(),
    params: {},
    lang: 'csharp',
    input_b64: input_b64,
  }
  console.log(getCurrentFormattedTime())
  fetch(URL, { mode: 'cors', method: 'POST', body: JSON.stringify(jsonData) })
    .then((response) => response.json())
    .then((json) =>
      Buffer.from(json['output_b64'], 'base64')
        .toString()
        .split('\n')
        .filter((v) => v !== '')
        .join(' ')
    )
    .then((command) => {
      console.log(command)
      console.log(getCurrentFormattedTime())
      const action = {
        type: 'GRIDSTATE_UPDATEALL',
        payload: {
          command: command,
        },
      }
      dispatch(action)
    })
    .catch((error) => console.log(error))
}

function getCurrentTimeString() {
  // yyyymmddhhmmss
  const now = new Date()
  const res =
    '' +
    now.getFullYear() +
    padZero(now.getMonth() + 1) +
    padZero(now.getDate()) +
    padZero(now.getHours()) +
    padZero(now.getMinutes()) +
    padZero(now.getSeconds())
  return res
}

function padZero(num: number) {
  return (num < 10 ? '0' : '') + num
}

function makeRunId() {
  return `run_${getCurrentTimeString()}_${makeRandomString(3)}`
}

function makeRandomString(length: number) {
  // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
  const result = []
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

function getCurrentFormattedTime() {
  // 現在の時刻を取得
  const now = new Date()

  // 各部分を取得
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0') // 月は0から始まるため+1
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0')

  // フォーマットされた時刻を作成
  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`

  // 結果を返す
  return formattedTime
}
