import React from 'react'
import './App.css'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import rootReducer from './reducers'
import AHC000 from './pages/AHC000/Page'

const store = createStore(
  rootReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
)

const defaultPage = AHC000

function App() {
  return (
    <Router>
      <Provider store={store}>
        <Route exact path="/" component={defaultPage}></Route>
        <Route exact path="/ahc-intro" component={AHC000}></Route>
      </Provider>
    </Router>
  )
}

export default App
