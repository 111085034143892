import { isNumber } from 'util'
import { XorShift } from './util'

export const initH = 365
export const initW = 26

export type StateInput = {
  contestPenaltyFactor: number[]
  contestHoldScoreMatrix: number[][]
  inputText: string
}

export class State {
  rand: XorShift
  inputText: string // 元の入力テキスト
  outputText: string // 解の出力テキスト
  header: string[] // ヘッダの表示名
  index: string[] // インデックスの表示名
  cells: CellState[][]
  rawScore: number // トータルスコア（update対象）
  contestPenaltyFactor: number[] // コンテスト種類ごとのペナルティの係数
  contestHoldScore: number[] // コンテスト種類ごとの開催した場合のスコア（update対象）
  contestPenalty: number[] // コンテスト種類ごとのペナルティ（update対象）

  constructor(H: number, W: number)
  constructor(state: State)
  constructor(a: any, b?: number) {
    if (a instanceof State) {
      // copy state
      const H = a.H()
      const W = a.W()
      this.rand = a.rand
      this.inputText = a.inputText
      this.outputText = a.outputText
      this.header = a.header
      this.index = a.index
      this.cells = a.cells
      // 再レンダー可能なように、それぞれ新しいオブジェクトにする
      for (let y = 0; y < H; y++) {
        for (let x = 0; x < W; x++) {
          this.cells[y][x] = { ...this.cells[y][x] }
        }
      }
      this.rawScore = a.rawScore
      this.contestPenaltyFactor = a.contestPenaltyFactor
      this.contestHoldScore = a.contestHoldScore
      this.contestPenalty = a.contestPenalty
    } else {
      const H = a
      const W = b as number
      const header = []
      for (let x = 0; x < W; x++) {
        header.push(`${String.fromCharCode('A'.charCodeAt(0) + x)}`)
      }
      const index = []
      for (let y = 0; y < H; y++) {
        index.push(`day ${y}`)
      }

      this.rand = new XorShift(71)
      this.outputText = Array(H)
        .fill(0)
        .map((_) => this.rand.randomInt(W) + 1)
        .join(' ')

      const cells = []
      for (let y = 0; y < H; y++) {
        const row = []
        for (let x = 0; x < W; x++) {
          const cell = {
            holdScore: 100,
            isHeld: false,
            penaltyCount: 0,
            penalty: 0,
          }
          row.push(cell)
        }
        cells.push(row)
      }

      const rawScore = 0
      const contestPenaltyFactor = new Array(W).fill(0)
      const contestHoldScore = new Array(W).fill(0)
      const contestPenalty = new Array(W).fill(0)

      this.inputText = ''
      this.header = header
      this.index = index
      this.cells = cells
      this.rawScore = rawScore
      this.contestPenaltyFactor = contestPenaltyFactor
      this.contestHoldScore = contestHoldScore
      this.contestPenalty = contestPenalty

      this.update()
    }
  }

  // コンストラクタのようなもの
  static constructFromStateInput(stateInput: StateInput): State {
    const H = stateInput.contestHoldScoreMatrix.length
    const W = stateInput.contestPenaltyFactor.length
    const state = new State(H, W)
    state.inputText = stateInput.inputText
    state.contestPenaltyFactor = stateInput.contestPenaltyFactor
    for (let y = 0; y < H; y++)
      for (let x = 0; x < W; x++) {
        state.cells[y][x].holdScore = stateInput.contestHoldScoreMatrix[y][x]
      }
    state.update()
    return state
  }

  H(): number {
    return this.index.length
  }

  W(): number {
    return this.header.length
  }

  score(): number {
    return 1000000 + this.rawScore
  }

  update(): void {
    const command = this.outputText.split(' ').map((e) => parseInt(e))

    this.rawScore = 0
    for (let x = 0; x < this.W(); x++) {
      this.contestHoldScore[x] = 0
      this.contestPenalty[x] = 0
      let penaltyCount = 0
      for (let y = 0; y < this.H(); y++) {
        const cell = this.cells[y][x]
        const isHeld = command[y] - 1 == x
        if (isHeld) {
          penaltyCount = 0
          const gain = cell.holdScore
          // update
          cell.isHeld = true
          cell.penaltyCount = 0
          cell.penalty = 0
          this.contestHoldScore[x] += gain
          this.rawScore += gain
        } else {
          penaltyCount += 1
          const penalty = -1 * this.contestPenaltyFactor[x] * penaltyCount
          // update
          cell.isHeld = false
          cell.penaltyCount = penaltyCount
          cell.penalty = penalty
          this.contestPenalty[x] += penalty
          this.rawScore += penalty
        }
      }
    }
  }
}

export type CellState = {
  isHeld: boolean // 開催しているかどうか
  holdScore: number // 開催した場合のスコア
  penaltyCount: number // 連続何回開催していないか（update対象）
  penalty: number // 非開催によるペナルティ（update対象）
}
