/* eslint-disable no-unused-vars */
import React from 'react'
import { Container, Row, Button, Input, Label, Col } from 'reactstrap'
import { useSelector } from 'react-redux'

const Controller: React.FC = () => {
  const state = useSelector((state: any) => {
    return state
  })

  return (
    <>
      <Row className="my-2" style={{ textAlign: 'left' }}>
        <h5>入力 (read only)</h5>
      </Row>
      <Row className="my-0" style={{ textAlign: 'left' }}>
        <Input
          style={{ height: '100px' }}
          disabled={true}
          type="textarea"
          name="textareaInput"
          id="textareaInput"
          value={state.AHC000.inputText}
        />
      </Row>
      <Row className="mt-4 my-2" style={{ textAlign: 'left' }}>
        <h5>出力 (read only)</h5>
      </Row>
      <Row className="my-0" style={{ textAlign: 'left' }}>
        <Input
          disabled={true}
          type="textarea"
          name="textareaOutput"
          id="textareaOutput"
          value={state.AHC000.outputText}
        />
      </Row>
    </>
  )
}

export default Controller
