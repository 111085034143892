import { State, StateInput } from './state'
import sample from './input/sample'
// 必要十分にre-renderされるように構成されている必要がある

function loadFromFile(): State {
  const lines = sample.split('\n')
  let lineIndex = 0
  const H = parseInt(lines[lineIndex++])
  const C = lines[lineIndex++].split(' ').map((v) => parseInt(v))
  const S = []
  for (let i = 0; i < H; i++) {
    S.push(lines[lineIndex++].split(' ').map((v) => parseInt(v)))
  }
  return State.constructFromStateInput({
    contestPenaltyFactor: C,
    contestHoldScoreMatrix: S,
    inputText: sample,
  })
}

function initialize(): State {
  return loadFromFile()
}

function randomize(state: State): State {
  const newState: State = new State(state)
  const W = newState.W()
  const H = newState.H()
  const command = new Array(H)
    .fill(0)
    .map((x, i) => newState.rand.randomInt(W) + 1)
    .join(' ')

  newState.outputText = command
  newState.update()
  return newState
}

function update(state: State, payload: any): State {
  const prevCommand = state.outputText
  const newCommand = prevCommand
    .split(' ')
    .map((x, y) => (y == payload.y ? payload.x + 1 : x))
    .join(' ')

  const newState: State = new State(state)
  newState.outputText = newCommand
  newState.update()
  return newState
}

function updateAll(state: State, payload: any): State {
  const command = payload.command
  const newState: State = new State(state)
  newState.outputText = command
  newState.update()
  return newState
}

export const initState = initialize()

export default function reducer(state: State = initState, action: any): any {
  const payload = action.payload
  switch (action.type) {
    case 'GRIDSTATE_RANDOMIZE':
      return randomize(state)
    case 'GRIDSTATE_UPDATE':
      return update(state, payload)
    case 'GRIDSTATE_UPDATEALL':
      return updateAll(state, payload)
    default:
      return state
  }
}
